import React from "react";
import ReactDOM from "react-dom";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { withRouter } from "react-router";
import { withStore } from "../../../store";
import { compose } from "redux";
import { SVG, Image } from "../../../assets/images";
import ContentRatingOverlay from "./ContentRatingOverlay/ContentRatingOverlay";
import PlayerLoadingOverlay from "./PlayerLoadingOverlay/PlayerLoadingOverlay";
import NextEpisode from "./NextEpisode/NextEpisode";
import Recommendations from "./Recommendations/Recommendations";
import HeaderBar from "./HeaderBar/HeaderBar";
import { Constants, User, Browser, Analytics } from "../../../utilities";
import ErrorPortal from "../../Error/Error";
import _ from "lodash";
import "./Player.scss";

const intervalTime = 3000;
const inactiveTime = 4000;

class Player extends React.Component<any, any> {
  player;
  video;
  contentItem;
  loadingContainer;
  subtitlesInitialized;
  autoplayed;
  updateTimer;
  watchedPastThreshold;
  showingEndScreen;

  mouseMoveCheckerTimeout;
  setMouseMoveTimeout;
  clearMouseMoveTimeout;
  resetMouseMoveTimeout;
  removeMouseMoveTimeout;

  constructor(props) {
    super(props);
    this.video = props.store.video;
  }

  /*******************************
   * LIFECYCLE EVENTS
   *******************************/

  componentDidMount() {
    this.toggleScrollLock(true);
    this.showLoading();
    document.addEventListener('keydown', function(event){
      if(event.key ==="Enter"){
        console.log('1111',event)
      }
    })
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  /*******************************
   * PROGRESS UPDATES
   *******************************/

  makePlaybackRequest = (url, body) => {
    return fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Authorization": User.authHeader()
      },
      body: JSON.stringify(body)
    })
  };

  updateProgress = () => {
    if(!this.player)
      return;

    const { id: contentId, seriesId } = this.contentItem;
    const progress = this.player.currentTime() / this.player.duration();

    clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(() => {
      this.makePlaybackRequest(Constants.UpdatePlaybackProgressUrl, { contentId, seriesId, progress })
        .then(this.updateProgress);
    }, intervalTime)
  }

  startNextProgress = () => {
    const { seriesId, next } = this.contentItem;
    if(!next)
      return;

    const { id: contentId } = next
    const progress = 0;
    this.makePlaybackRequest(Constants.UpdatePlaybackProgressUrl, { contentId, seriesId, progress })
  }

  completeProgress = () => {
    if(!this.updateTimer)
      return;

    this.stopProgress();
    Analytics.mediaReportEnd();
    this.makePlaybackRequest(Constants.CompletePlaybackProgressUrl, { contentId: this.contentItem.id })
      .then(this.startNextProgress)
  }

  stopProgress = () => {
    clearTimeout(this.updateTimer);
    this.updateTimer = undefined;
  }

  /*******************************
   * HELPER FUNCTIONS
   *******************************/

  isPlaybackComplete = () => {
    const currentTime = this.player.currentTime();
    const duration = this.player.duration();
    return (currentTime < Constants.ContinuityStartTime && this.contentItem.progress === null) ||
      (currentTime > duration - Constants.ContinuityEndTime);
  }

  showLoading = () => {
    ReactDOM.render(
      <PlayerLoadingOverlay className="Player__loading" contentItem={this.video} />,
      this.loadingContainer
    );
    this.loadingContainer.classList.remove("nodisplay");
  }

  hideLoading = () => {
    this.loadingContainer.classList.add("nodisplay");
  }

  hideEndScreen = () => {
    const el_ = document.querySelector(".vjs-endscreen");
    el_ && el_.remove();
  }

  cleanUp = () => {
    const { dismissVideo } = this.props;
    Analytics.mediaReportStop();
    this.stopProgress();
    dismissVideo();
    this.toggleScrollLock(false);
  }

  toggleScrollLock = (locked) => {
    const body = document.querySelector("body");
    locked ? body!.classList.add("scroll-lock-player") : body!.classList.remove("scroll-lock-player");
  }

  dismiss = () => {
    const { history } = this.props;
    history.goBack();
  }

  advance = () => {
    this.stopProgress();
    Analytics.mediaReportStop();
    this.video = this.contentItem.next;
    this.autoplayed = true;
    this.hideEndScreen();
    this.showLoading();
    this.getContentMetadata();
  }

  forward = (url) => {
    const { history } = this.props;
    history.replace(url, { fromRecommendation: true });
  }

  createDiv = (className, ...rest) => {
    return (new DOMParser()).parseFromString(`<div class="${className}" tabindex=${rest[0] ? "-1" : "0"} aria-label=${rest[1]?rest[1]:""} />`, "text/html").body.firstChild as HTMLElement;
  }

  getContentMetadata = () => {
    const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/playback/metadata/${this.video.id}`;

    fetch(url, {
      headers: {
        "content-type": "application/json",
        "Authorization": User.authHeader()
      }
    }).then(async res => {
      const data = await res.json();
      this.video = this.contentItem = data.data.contentItem;

       this.player.catalog.getVideo(`ref:${this.video.extId}`, (err, vid) => {
       //   this.player.catalog.getVideo(`ref:the_imaginary_anim`, (err, vid) => {
        if(err) {
          this.onError();
        }
        else {
          this.createHeaderBar();
          this.createNextEpisodeButton();
          this.player.catalog.load(vid);
        }
      })
    })
  }

  checkSubtitleAvailability = () => {
    let issubtitleAvailable = false;
    const subtitleSection = document.querySelector("div.vjs-subs-caps-button")!
    const languageOptions = this.props.store.subtitlelanguages;
    let subtitleSectionUls = subtitleSection!.querySelectorAll<HTMLElement>('.vjs-subtitles-menu-item');
    subtitleSectionUls.forEach((node, index) => {
      subtitleSectionUls[index].style.display = 'none';
       for (let i = 0; i < languageOptions.length; i++) {
        if ((node.firstElementChild as HTMLElement).innerText.includes(languageOptions[i]['label']) ||
          (node.firstElementChild as HTMLElement).innerText.includes(languageOptions[i]['iso1'])) {
            subtitleSectionUls[index].style.display = 'flex';
            issubtitleAvailable = true;
            (node.firstElementChild as HTMLElement).innerText = languageOptions[i]['label']
        }
      }
    });
    if(!issubtitleAvailable) {
      subtitleSection.classList.add("nodisplay");
    }
  }
  checkAudioAvailability = () => {
    let isAudioAvailable = false;
    const audioSection = document.querySelector("div.vjs-audio-button")!
    let audioOptions = this.props.store.audiolanguages;
    audioOptions = audioOptions.map(option => option.iso1);
    let audioSectionUls = audioSection!.querySelectorAll<HTMLElement>('.vjs-menu-item');
    audioSectionUls.forEach((node, index) => {
      audioSectionUls[index].style.display = 'none';
      for (let i = 0; i < audioOptions.length; i++) {
        if ((node.firstElementChild as HTMLElement).innerText.substring(0, 2).includes(audioOptions[i])) {
          audioSectionUls[index].style.display = 'flex';
          isAudioAvailable = true;
        }
      }
    });
    if(!isAudioAvailable) {
      audioSection.classList.add("nodisplay");
    }
  }

  setupAudio = () => {
    const { store: { settings } } = this.props;
    const { audio, language } = settings;
    const audioTracks = this.player.audioTracks();

    const audioSection = document.querySelector("div.vjs-audio-button")!;
    const audioButton = audioSection.querySelector("button")!;
    const audioOptions = this.props.store.audiolanguages;


     const audioOptionsHashMap = audioOptions.reduce((map:any, language:any) => {
      map[language.iso1] = language;  // Key: iso1, Value: whole object
     return map;
    }, {});

    if (!audioTracks.length || !audioOptions || audioOptions.length <= 0)
      audioSection.classList.add("nodisplay");
    else {

      // Add listener so button is properly highlighted when subs are on/off
      audioTracks.on("change", () => {
        const audioOn = _.filter(audioTracks, track => track.enabled).length;

        if (audioOn) {
          const audioSelected = audioSection!.querySelector('.vjs-menu-item.vjs-selected');
          if (audioSelected) {
            audioOptions.forEach((option) => {
              if ((audioSelected.firstElementChild as HTMLElement).innerText.substring(0, 2).includes(option.iso1)) {
                // this.props.updateSettings("audio", option.iso1)
                audioButton.classList.add('selected');
              }
            })
          }
        }
      });

         // changing label start 
      
         setTimeout(() => {
          const audioButton = document.querySelector('.vjs-audio-button');
        
          if (audioButton) {
            const menuList = audioButton.querySelector('ul');
        
            if (menuList) {
              const menuItems = menuList.querySelectorAll('li');
        
              menuItems.forEach(item => {
                const menuItemText:any = item.querySelector('.vjs-menu-item-text');
                if (menuItemText) {
                  const trimmedText = menuItemText.innerText.trim().substring(0, 2);
        
                  const matchingOption = audioOptionsHashMap[trimmedText];
        
                  if (matchingOption) {
                    menuItemText.innerText = matchingOption.label;
                  }
                }
              });
            }
          }
        }, 1500);
  
        // change label end

      // Set default audio
      setTimeout(()=>{
        if (audio &&  _.filter(audioTracks, track => track.language == audio).length) {
          for (let i = 0; i < audioTracks.length; i++) {
            if (audioTracks[i].language === audio) {
              audioTracks[i].enabled = true;
            }
            else {
              audioTracks[i].enabled = false;
            }
          }
          audioOptions.forEach((option) => {
            if (option.iso1 == audio) {
              audioButton.classList.add('selected');
            }
            else {
              audioButton.classList.remove('selected');
            }
          })
        }
      },1000)
    }
  }

  setupSubtitles = () => {
    const { store: { settings } } = this.props;
    const { subtitles, language } = settings;
    const textTracks = this.player.textTracks();

    const languageOptions = this.props.store.subtitlelanguages;
    const subtitleSection = document.querySelector("div.vjs-subs-caps-button")!;
    const subtitleButton = subtitleSection.querySelector("button")!;
    const subtitlesOffButton = subtitleSection!.querySelector(".vjs-menu-item:nth-of-type(2)")!;

    if (!textTracks.length || !languageOptions || languageOptions.length <= 0)
      subtitleSection.classList.add("nodisplay");
    else {
      // Change text of buttons
      subtitleButton.setAttribute("title", "Subtitles");
      if (!Browser.isSafari())
        subtitlesOffButton.querySelector("span")!.textContent = "Subtitles off";

      // Add listener so button is properly highlighted when subs are on/off
      textTracks.on("change", () => {
        let subtitlesOn = _.filter(textTracks, track => track.mode === "showing").length;

        if (subtitlesOn) {
          subtitleButton.classList.add("selected");
          const subtitleSelected = subtitleSection!.querySelector('.vjs-subtitles-menu-item.vjs-selected');
          if (subtitleSelected) {
            languageOptions.forEach((option) => {
              if ((option.label == (subtitleSelected.firstElementChild as HTMLElement).innerText) ||
                (option.iso1 == (subtitleSelected.firstElementChild as HTMLElement).innerText)) {
                this.props.updateSettings("language", option.iso1)
                this.props.updateSettings("subtitles", true);
              }
            })
          }
        }
        else {
          subtitleButton.classList.remove("selected");
          this.props.updateSettings("subtitles", false);
        }
      });


      for (let i = 0; i < textTracks.length; i++) {
        textTracks[i].mode = "disabled";
      }
      // Set default language
      if (subtitles) {
        for (let i = 0; i < textTracks.length; i++) {
          if (textTracks[i].language === language) {
            this.subtitlesInitialized = true;
            subtitleButton.classList.add("selected");
            textTracks[i].mode = "showing";
            break;
          }
          else {
            this.subtitlesInitialized = true;
            textTracks[i].mode = "disabled";
          }
        }
      }
    }
  }

  checkQualitySetting = () => {
    if(Browser.isSafari())
      return;

    const { store: { settings } } = this.props;
    const { quality } = settings;

    try {
      this.player.tech().hls.representations().forEach(rep => {
        const enabled = (quality === "SD" && rep.height < 720) || (quality === "HD" && rep.height >= 720);
        rep.enabled(enabled);
      });
    } catch(err) {
      console.log("Quality selection isn't supported in this browser.");
    }
  }

  getProgressHandlePosition = () => {
    const handleEl_ = document.querySelector(".vjs-progress-handle")!;
    const { width } = this.player.controlBar.progressControl.el_.getBoundingClientRect();
    const percentage = this.player.currentTime() / this.player.duration();
    return `${(width * percentage) - (handleEl_.clientWidth / 2)}px`;
  }

  onError = () => {
    const { history } = this.props;
    ErrorPortal.error(undefined, () => history.goBack(), "Try again");
  }

  /*******************************
   * GENERAL PLAYER UI
   *******************************/

  createMouseEventListeners = () => {
    const controlBarEl_ = this.player.controlBar.el_;

    this.setMouseMoveTimeout = () => {
      this.mouseMoveCheckerTimeout = setTimeout(() => {
        controlBarEl_.classList.add("inactive");
      }, inactiveTime);
    }

    this.clearMouseMoveTimeout = () => {
      clearTimeout(this.mouseMoveCheckerTimeout);
      this.mouseMoveCheckerTimeout = null;
      controlBarEl_.classList.remove("inactive");
    }

    this.resetMouseMoveTimeout = () => {
      this.clearMouseMoveTimeout();
      this.setMouseMoveTimeout();
    }

    this.removeMouseMoveTimeout = () => {
      clearTimeout(this.mouseMoveCheckerTimeout);
      this.mouseMoveCheckerTimeout = null;
      controlBarEl_.classList.add("inactive");
    }

    this.player.controlBar.el_.addEventListener("mousemove", this.resetMouseMoveTimeout);
    this.player.controlBar.el_.addEventListener("keydown", (e) =>{
      if (e.keyCode === 9) {
        try{
          this.resetMouseMoveTimeout();
        }catch (er){
          console.warn(er, "custom error 2")
        }
      }
      if (e.keyCode === 13) {
        console.log(e);
        try{
          e.currentTarget.click();
        }catch(e) {
          console.warn(e, 'custom error')
        }
      }
    }
    );
    this.player.on("loadeddata", this.setMouseMoveTimeout);
  }

  createMidBlockBarComponents = () => {
    const controlBarEl_ = this.player.controlBar.el_;
    const midBlockDiv = this.createDiv("Video-mid-block");
    const buttonGroupDiv = this.createDiv("Video-mid-button-group");
    const backwardButton = this.createDiv("Video-mid-buttons backward-button" , false, "Backward Button");
    const playButton = this.createDiv("Video-mid-buttons play-button", false, "Play Button");
    const forwardButton = this.createDiv("Video-mid-buttons forward-button", false, "Forward Button");

    ReactDOM.render(
      <SVG.BackwardSVG />,
      backwardButton
    );

    ReactDOM.render(
      <SVG.Pause />,
      playButton
    );

    ReactDOM.render(
      <SVG.ForwardSVG />,
      forwardButton
    );

    const animatedButton = (button, svgValue, resetRequired, callback) => {
      button.classList.add("play-button-zoom-out");
      setTimeout(() => {
        button.classList.remove("play-button-zoom-out");
        ReactDOM.render(
          <div className='play-button-boundary-circle'>{svgValue}</div>,
          button
        );
        setTimeout(() => {
          if (resetRequired) {
            ReactDOM.render(
              svgValue,
              button
            );
          }
          callback();
        }, 250);
      }, 250);
    }

    const onBackwardClick = () => {
      const callback = () => {
        this.player.currentTime(Math.max(0, this.player.currentTime() - 10));
      }
      animatedButton(backwardButton, <SVG.BackwardSVG />, true, callback);
    };

    const onPlayClick = () => {
      if (this.player.paused()) {
        const callback = () => {
          this.player.play();
        }
        animatedButton(playButton, <SVG.Pause />, false, callback);
      } else {
        const callback = () => {
          this.player.pause();
        }
        animatedButton(playButton, <SVG.LargePlay />, false, callback);
      }
    };

    const onForwardClick = () => {
      const callback = () => {
        this.player.currentTime(Math.min(this.player.duration(), this.player.currentTime() + 10));
      }
      animatedButton(forwardButton, <SVG.ForwardSVG />, true, callback);
    };

    this.player.on("play", () => {
      playButton.classList.add("playing");

      ReactDOM.render(
        <SVG.Pause />,
        playButton
      );
      Analytics.mediaReportPlay();
    });

    this.player.on("pause", () => {
      playButton.classList.remove("playing");

      ReactDOM.render(
        <SVG.LargePlay />,
        playButton
      );

      this.stopProgress();
      Analytics.mediaReportPause();
    })

    backwardButton.addEventListener("click", onBackwardClick);
    playButton.addEventListener("click", onPlayClick);
    forwardButton.addEventListener("click", onForwardClick);

    buttonGroupDiv.appendChild(backwardButton);
    buttonGroupDiv.appendChild(playButton);
    buttonGroupDiv.appendChild(forwardButton);
    midBlockDiv.appendChild(buttonGroupDiv);

    controlBarEl_.insertBefore(midBlockDiv, controlBarEl_.firstChild);
  }

  createVolumeComponents = () => {
    const controlBar = this.player.controlBar;
    const volumeBarEl_ = controlBar.volumePanel.volumeControl.volumeBar.bar.el_;
    const volumeButtonEl_ = controlBar.volumePanel.muteToggle.el_
    const volumeHandleEl_ = this.createDiv("vjs-volume-handle");
    const volumeIconContainer = document.createElement("div");

    volumeBarEl_.appendChild(volumeHandleEl_);
    volumeButtonEl_.appendChild(volumeIconContainer);

    ReactDOM.render(
      <SVG.Volume />,
      volumeIconContainer
    );

    this.player.on("volumechange", () => {
      ReactDOM.render(
        (this.player.muted() || !this.player.volume()) ? <SVG.VolumeOff /> : <SVG.Volume />,
        volumeIconContainer
      );
    });
  }

  createSeekComponents = () => {
    const videoEl_ = this.player.el_;
    const seekBarEl_ = this.player.controlBar.progressControl.seekBar.bar.el_;
    const tooltipEl_ = this.player.controlBar.progressControl.seekBar.bar.timeTooltip.el_;
    const mouseDisplay = this.player.controlBar.el_.querySelector(".vjs-mouse-display")
    const progressHandleEl_ = this.createDiv("vjs-progress-handle");

    seekBarEl_.appendChild(progressHandleEl_);

    this.player.on("timeupdate", () => {
      const { store: { settings: { autoplay } } } = this.props;
      const canAutoplay = this.player.currentTime() >= (this.player.duration() - Constants.AutoplayCountdownTime);
      const isPastThreshold = this.player.currentTime() >= (this.player.duration() * Constants.PlayerAnalyticsPercentage);
      const isComplete = this.isPlaybackComplete();

      if (!(videoEl_.classList.contains("vjs-waiting") || videoEl_.classList.contains("vjs-seeking")) || videoEl_.classList.contains("vjs-paused")) {
        progressHandleEl_.style.left = this.getProgressHandlePosition();;
        progressHandleEl_.style.right = "";
      }

      if (isComplete) {
        this.completeProgress();

        if (canAutoplay && autoplay && this.contentItem.next) {
          this.removeMouseMoveTimeout();
          this.createEndScreenDiv("next");
        }
      }
      else if (this.player.hasStarted_ && !this.updateTimer) {
        this.updateProgress();
      }

      if (isPastThreshold && !this.watchedPastThreshold) {
        Analytics.userActionWatchedContentPastThreshold(this.contentItem);
        this.watchedPastThreshold = true;
      }
    });

    this.player.on("seeking", () => {
      const videoEl_ = this.player.el_;
      const mouseDisplayLeft = Number(mouseDisplay.style.left.replace(/px/, ""));
      const toolTipRight = Number(tooltipEl_.style.right.replace(/px/, ""));
      const left = `${mouseDisplayLeft + toolTipRight}px`;

      if (videoEl_.classList.contains("vjs-scrubbing")) {
        progressHandleEl_.style.left = left;
        progressHandleEl_.style.right = "";
      }

      Analytics.mediaReportMove();
    });
  }

  createLoadingComponent = () => {
    const videoEl_ = this.player.el_;
    const controlBarEl_ = this.player.controlBar.el_;
    const loadingSpinnerEl_ = this.player.loadingSpinner.el_;

    videoEl_.insertBefore(loadingSpinnerEl_, controlBarEl_.nextSibling);

    ReactDOM.render(
      <img src={Image.Loading} alt="loading" />,
      loadingSpinnerEl_
    );
  }

  createTimeDisplayComponent = () => {
    const controlBarEl_ = this.player.controlBar.el_;
    const volumePanelEl_ = this.player.controlBar.volumePanel.el_;
    const currentTimeEl_ = this.player.controlBar.currentTimeDisplay.el_;
    const timeDividerEl_ = this.player.controlBar.timeDivider.el_;
    const durationEl_ = this.player.controlBar.durationDisplay.el_;
    const timeDisplayContainer = this.createDiv("vjs-time-display");

    timeDisplayContainer.appendChild(currentTimeEl_);
    timeDisplayContainer.appendChild(timeDividerEl_);
    timeDisplayContainer.appendChild(durationEl_);
    controlBarEl_.insertBefore(timeDisplayContainer, volumePanelEl_.nextSibling);
  }

  createFullscreenComponent = () => {
    const containerEl_ = document.querySelector(".rightside-controls")!;
    const controlBar = this.player.controlBar;
    const controlBarEl_ = controlBar.el_;
    const fullscreenButtonEl_ = controlBar.fullscreenToggle.el_;
    const fullscreenIconContainer = document.createElement("div");
    fullscreenButtonEl_.appendChild(fullscreenIconContainer);

    ReactDOM.render(
      <SVG.Fullscreen />,
      fullscreenIconContainer
    );

    this.player.on("fullscreenchange", () => {
      if (this.player.isFullscreen()) {
        ReactDOM.render(
          <SVG.ExitFullscreen />,
          fullscreenIconContainer
        );
      } else {
        ReactDOM.render(
          <SVG.Fullscreen />,
          fullscreenIconContainer
        );
      }
    });

    controlBarEl_.addEventListener("dblclick", () => {
      if (this.player.isFullscreen())
        this.player.exitFullscreen();
      else
        this.player.requestFullscreen();
    });

    containerEl_.append(fullscreenButtonEl_);
  }

  createControls = () => {
    const rightsideControlsDiv = this.createDiv("vjs-control rightside-controls");
    const controlBarEl_ = this.player.controlBar.el_;
    controlBarEl_.append(rightsideControlsDiv);
    this.createFullscreenComponent();
    this.createSubtitlesButton();
    this.createAudioButton();
  }

  /*******************************
   * CONTENT SPECIFIC PLAYER UI
   *******************************/

  createContentRatingOverlay = () => {
    const playerEl_ = document.querySelector(".Player")!;
    const contentRatingOverlay = document.querySelector(".vjs-content-rating") || this.createDiv("vjs-content-rating");
    playerEl_.appendChild(contentRatingOverlay);

    ReactDOM.render(
      <ContentRatingOverlay autoplayed={this.autoplayed} contentItem={this.contentItem} player={this.player} goBack={this.dismiss} />,
      contentRatingOverlay
    );
  }

  createHeaderBar = () => {
    const controlBarEl_ = this.player.controlBar.el_;
    const headerBarDiv = document.querySelector(".Video-header-bar") || this.createDiv("Video-header-bar", true);
    controlBarEl_.insertBefore(headerBarDiv, controlBarEl_.firstChild);

    ReactDOM.render(
      <HeaderBar contentItem={this.contentItem} onClose={this.dismiss} />,
      headerBarDiv
    );
  }

  createAudioButton = () => {
    const containerEl_ = document.querySelector(".rightside-controls")!;
    const audioButtonDiv = document.querySelector("div.vjs-audio-button")!;
    const audioButtonEl_ = this.player.controlBar.audioTrackButton.menuButton_.el_;
    const captionsIconContainer = this.createDiv("vjs-audio-container")
    audioButtonEl_.appendChild(captionsIconContainer);

    ReactDOM.render(
      <div>Audio<SVG.AudioSVG /></div>,
      captionsIconContainer
    );

    containerEl_.insertBefore(audioButtonDiv, containerEl_.lastChild);
  }

  createSubtitlesButton = () => {
    const containerEl_ = document.querySelector(".rightside-controls")!;
    const captionsButtonDiv = document.querySelector("div.vjs-subs-caps-button")!;
    const captionsButtonEl_ = this.player.controlBar.subsCapsButton.menuButton_.el_;
    const captionsIconContainer = this.createDiv("vjs-captions-container")
    captionsButtonEl_.appendChild(captionsIconContainer);

    ReactDOM.render(
      <div>Subtitles<SVG.Subtitles /></div>,
      captionsIconContainer
    );

    containerEl_.insertBefore(captionsButtonDiv, containerEl_.firstChild);
  }

  createNextEpisodeButton = () => {
    const containerEl_ = document.querySelector(".rightside-controls")!;
    const nextEpisodeButton = document.querySelector(".vjs-next-button") || this.createDiv("vjs-control vjs-button vjs-next-button");
    nextEpisodeButton.addEventListener("click", this.advance);

    if (!this.contentItem.next) {
      nextEpisodeButton.classList.add("nodisplay");
      return;
    }
    else {
      nextEpisodeButton.classList.remove("nodisplay");
    }

    ReactDOM.render(
      <div>Next episode<SVG.Arrow /></div>,
      nextEpisodeButton
    );

    containerEl_.insertBefore(nextEpisodeButton, containerEl_.firstChild);
  }

  createEndScreenDiv = (type) => {
    if (this.showingEndScreen)
      return;

    const videoEl_ = this.player.el_;
    const endScreenDiv = document.querySelector(".vjs-endscreen") || this.createDiv("vjs-endscreen");
    videoEl_.appendChild(endScreenDiv);
    this.showingEndScreen = true;
    if (type !== "next" && this.contentItem.length !== 0 && this.contentItem.recommendations.length !== 0) {
      const items = _.get(this.contentItem, "recommendations.collection.items.nodes");
      for (let i = 0; i < items.length; i++) {
        let item = `${items[i].name}`
        let position = "0-" + (i + 1)
        Analytics.userActionRecommendationsImpression(item, position);
      }
    }

    ReactDOM.render(
      type === "next" ?
        <NextEpisode
          contentItem={this.contentItem}
          onClose={this.dismiss}
          onAdvance={this.advance}
        /> :
        <Recommendations
          contentItem={this.contentItem}
          onClose={this.dismiss}
          onClick={this.forward}
        />,
      endScreenDiv
    );
  }

  /*******************************
   * GENERAL
   *******************************/

onSuccess = (success) => {
  this.player = success.ref;

  this.createMouseEventListeners();
  this.createLoadingComponent();
  this.createMidBlockBarComponents();
  this.createVolumeComponents();
  this.createSeekComponents();
  this.createTimeDisplayComponent();
  this.createControls();

    this.player.on("loadedmetadata", () => {
      this.hideLoading();
      this.showingEndScreen = false;
      this.checkQualitySetting();
      Analytics.userActionPlayContent(this.contentItem);
      Analytics.mediaReportInit(this.contentItem, this.player);
      this.createContentRatingOverlay();
    });

    this.player.on("loadeddata", () => {
      this.setupSubtitles()
      this.setupAudio();
      this.checkSubtitleAvailability();
      this.checkAudioAvailability();
    })

    this.player.on("error", this.onError);

    this.player.on("ended", () => {
      const { store: { settings: { autoplay } } } = this.props;

      this.completeProgress();

      if (!this.contentItem.next || !autoplay) {
        this.removeMouseMoveTimeout();
        this.createEndScreenDiv("recommendations");
      }
    });

    window.onpopstate = () => {
      this.cleanUp();
    };

    this.getContentMetadata();
  }

  render() {
    return (
      <React.Fragment>
        <ReactPlayerLoader
          refNode="#player"
          attrs={{ className: "Player" }}
          accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}
          playerId={process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID}
          onSuccess={this.onSuccess}
          onFailure={this.onError}
        />
        <div ref={node => this.loadingContainer = node} className="Player__loading-container" />
      </React.Fragment>
    )
  }
}

export default compose<any>(withRouter, withStore)(Player);
